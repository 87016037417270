import React from "react";
import "./LicenseCards.css";

const LicenseCards = () => {
  const licenses = [
    {
      title: "Free Zone",
      subtitle: "Company Setup",
      price: "5,350",
      features: [
        "Lowest price guaranteed",
        "100% ownership",
        "0% income and corporate tax",
        "5 free business activities",
        "Free consultation",
      ],
    },
    {
      title: "Free Zone",
      subtitle: "License with 1 UAE Residency Visa",
      price: "14,500",
      features: [
        "100% ownership",
        "5 free business activities",
        "0% income and corporate tax",
        "Free lease agreement",
        "Free consultation",
        "All inclusive",
      ],
    },
    {
      title: "Free Zone",
      subtitle: "License with 2 FREE UAE Visas For Life",
      price: "18,000",
      features: [
        "2 free UAE visas for life included",
        "100% ownership",
        "5 free business activities",
        "0% income and corporate tax",
        "Free lease agreement",
        "Free VAT Registration",
        "Free consultation",
        "Accounting and compliance assistance",
      ],
    },
  ];

  // Function to scroll to the contact form section
  const scrollToContactForm = () => {
    document
      .getElementById("contact-form")
      .scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="license-container">
      <div className="intro-section">
        <h2>
          There has never been a better time to start your business in the UAE!
        </h2>
        <p>
          Thanks to this new flexible payment solution, you will be able to
          split the cost of your free zone license and 2-year UAE residency visa
          at no additional cost. Lay the foundation for your business dreams
          with just <strong>AED 5,999</strong> upfront.
        </p>
      </div>

      <div className="info-section">
        <div className="info-box">
          <h3>NO hidden charges</h3>
          <p>
            The total cost for your free zone trade license and 2-year UAE
            residency visa is <strong>AED 11,999</strong>. At the time of the
            upfront payment, please provide a post-dated cheque for the
            remaining 50% which will be collected after 6 months.
          </p>
        </div>
        <div className="info-box">
          <h3>General Trading & E-Commerce</h3>
          <p>
            These are the most popular business activities our clients are
            looking for. And with this special offer, you can combine both on
            the SAME license at no additional cost. If you are planning to sell
            online or trade internationally, this package is for YOU.
          </p>
        </div>
      </div>

      <h2 className="license-heading">Find The Right License</h2>
      <div className="license-cards license-card-main">
        {licenses.map((license, index) => (
          <div key={index} className="card license-card license-sub">
            <h3 className="card-title">{license.title}</h3>
            <h4 className="card-subtitle">{license.subtitle}</h4>
            <div className="card-price">AED {license.price}</div>
            <ul className="card-features">
              {license.features.map((feature, i) => (
                <li key={i}>{feature}</li>
              ))}
            </ul>
            <button className="enquire-button" onClick={scrollToContactForm}>
              Enquire Now
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LicenseCards;
