import React from 'react';
import './Header.css';
import logo from '../../Assets/logo.png'

const Header = () => {
  return (
    <div className="express-pro-container">
        <div className="logo"><span>TECHNIP</span> 
            <img src={logo} alt="" />
        </div>
      <div className="promo-section">
        <div className="contact-info">
          <a href="mailto:bilal@wilspun.com">bilal@wilspun.com</a>
          <a href="tel:+97145840955">+971 58 267 6585</a>
        </div>
        <div className="lowest">
          <h1>Lowest</h1>
          </div>
          <div className='price'>
            <h1>Price</h1>
          </div>
          <div className='gurantee'>
            <h1>Gurantee</h1>
          </div>
          <div>
          <p>Business License & 2-Years UAE Residency Visa</p>
        </div>
        <div className="price-info">
          <span>From just AED</span>
          <h2>5,999</h2>
          <p>upfront</p>
        </div>
      </div>

      <div className="form-section">
        <h2>Best Service. Lowest Price.</h2>
        <p>Fill in your details below to receive a callback from our experts.</p>
        <form>
          <input type="text" placeholder="First Name" required />
          <input type="text" placeholder="Last Name" required />
          <input type="email" placeholder="Email Address" required />
          <input type="tel" placeholder="Phone Number" required />
          <textarea placeholder="Tell us more about the business you are looking to set up" rows="4" required></textarea>
          <button type="submit">GET STARTED</button>
        </form>
      </div>
    </div>
  );
};

export default Header;
