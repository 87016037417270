import React from "react";
import "./GovernmentAgencies.css";
import agency_1 from '../../Assets/government-of-dubai.png'
import agency_2 from '../../Assets/united-arab-emirates-ministry-of-finanace.png'
import agency_3 from '../../Assets/federal-tax-authority.png'
import agency_4 from '../../Assets/dubai-police.png'
import agency_5 from '../../Assets/dubai-expo-2020.png'
import agency_6 from '../../Assets/dubai-health-authority.png'
import agency_7 from '../../Assets/emirates-identity-authority.png'
import agency_8 from '../../Assets/ICP.png'


const GovernmentAgencies = () => {
  return (
    <div className="agencies-container">
      <h2>
        We work closely with all 
      </h2>
      <span className="highlight">Government Agencies</span>
      <p>
        We are constantly liaising with various government departments and stay up to date with the latest government requirements,
        processes, and timelines to set your company up for success.
      </p>
      <div className="agencies-logos">
        <div className="logo-item">
          <img src={agency_1} alt="Government of Dubai" />
        </div>
        <div className="logo-item">
          <img src={agency_2} alt="UAE Ministry of Finance" />
        </div>
        <div className="logo-item">
          <img src={agency_3} alt="Federal Tax Authority" />
        </div>
        <div className="logo-item">
          <img src={agency_4} alt="Dubai Police" />
        </div>
        <div className="logo-item">
          <img src={agency_5} alt="Expo 2020" />
        </div>
        <div className="logo-item">
          <img src={agency_6} alt="Dubai Health Authority" />
        </div>
        <div className="logo-item">
          <img src={agency_7} alt="Emirates Identity Authority" />
        </div>
        <div className="logo-item">
          <img src={agency_8} alt="Federal Authority for Identity & Citizenship" />
        </div>
      </div>
    </div>
  );
};

export default GovernmentAgencies;
