import React from "react";
import "./BusinessSetup.css";
import zoneimg from '../../Assets/Freezone_card.png'
import mainimg from '../../Assets/Mainland_card.png'
import offshoreimg from '../../Assets/Offshore_card.png'

const BusinessSetup = () => {
  return (
    <div className="business-setup-wrapper">
      <div className="header">
        <h1>Professional and Affordable</h1>
        <h2>
          <span>Business Setup in Dubai & UAE</span>
        </h2>
        <p>
          Get more for less. We specialise in free zone, mainland and offshore
          company formation in the UAE.
        </p>
      </div>
      <div className="cards">
        <div className="card">
          <img
            src={zoneimg} // Replace with actual Freezone image URL
            alt="Freezone"
          />
          <h3>FREEZONE</h3>
          <p>Choose from over 50+ jurisdictions.</p>
          <button>Enquire Now</button>
        </div>
        <div className="card">
          <img
            src={mainimg} // Replace with actual Mainland image URL
            alt="Mainland"
          />
          <h3>MAINLAND</h3>
          <p>Operate anywhere in the UAE.</p>
          <button>Enquire Now</button>
        </div>
        <div className="card">
          <img
            src={offshoreimg} // Replace with actual Offshore image URL
            alt="Offshore"
          />
          <h3>OFFSHORE</h3>
          <p>Incorporate in 5 working days.</p>
          <button>Enquire Now</button>
        </div>
      </div>
    </div>
  );
};

export default BusinessSetup;
