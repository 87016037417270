import './App.css';
import FadeInSection from './Components/FadeInSection/FadeInSection';
import BenefitsSection from './Components/BenefitsSection/BenefitsSection';
import BusinessExpress from './Components/BusinessExpress/BusinessExpress';
import BusinessSetup from './Components/BusinessSetup/BusinessSetup';
import ConsultationBanner from './Components/ConsultationBanner/ConsultationBanner';
import ContactForm from './Components/ContactForm/ContactForm';
import Footer from './Components/Footer/Footer';
import GovernmentAgencies from './Components/GovernmentAgencies/GovernmentAgencies';
import Header from './Components/Header/Header';
import LicenseCards from './Components/LicenseCards/LicenseCards';
import Testimonials from './Components/Testimonials/Testimonials';

function App() {
  return (
    <div className="App">
      <FadeInSection><Header /></FadeInSection>
      <FadeInSection><LicenseCards /></FadeInSection>
      <FadeInSection><ConsultationBanner /></FadeInSection>
      <FadeInSection><Testimonials /></FadeInSection>
      <FadeInSection><BusinessSetup /></FadeInSection>
      <FadeInSection><BenefitsSection /></FadeInSection>
      <FadeInSection><GovernmentAgencies /></FadeInSection>
      <FadeInSection><BusinessExpress /></FadeInSection>
      <FadeInSection><ContactForm /></FadeInSection>
      <FadeInSection><Footer /></FadeInSection>
    </div>
  );
}

export default App;
