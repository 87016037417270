import React from 'react';
import './BenefitsSection.css';
import logo_1 from '../../Assets/image-1.png';
import logo_2 from '../../Assets/image-2.png';
import logo_3 from '../../Assets/image-3.png';
import logo_4 from '../../Assets/image-4.png';
import logo_5 from '../../Assets/image-5.png';
import logo_6 from '../../Assets/image-6.png';


const BenefitsSection = () => {
  const benefits = [
    {
      icon: logo_1, // Replace with actual icon paths or components
      text: 'Pay 0% personal and income tax, maintain 100% ownership'
    },
    {
      icon: logo_2,
      text: 'Easy to apply dependent visas and sponsor your family'
    },
    {
      icon: logo_3,
      text: "Ranked World's 2nd Safest City and 4th Best Place To Live And Work"
    },
    {
      icon: logo_4,
      text: 'State of the art healthcare and infrastructure'
    },
    {
      icon: logo_5,
      text: 'Live in a vibrant international community'
    },
    {
      icon: logo_6,
      text: 'Strategically located with easy access to major markets in Asia, Africa and Europe'
    }
  ];

  return (
    <section className="benefits-section">
      <h2>Benefits of setting up a <span>business in Dubai</span></h2>
      <p>The advantages of being a business owner in one of the top global hotspots for entrepreneurs are many – and it’s more affordable than you think!</p>
      <div className="benefits-grid">
        {benefits.map((benefit, index) => (
          <div className="benefit-item" key={index}>
            <img src={benefit.icon} alt="Benefit icon" className="benefit-icon" />
            <p>{benefit.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default BenefitsSection;
