import React, { useState } from 'react';
import './Testimonials.css';
import googleLogo from '../../Assets/google-logo.png'; // Import your Google logo image here

// Import Font Awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';

const testimonials = [
  {
    name: 'Sarim Ansari',
    review: 'I highly recommend the ExpressPRO team. The whole process of setting up my business in the UAE was very fast and the team supported me every step of the way. The PROs Mr. Furqan and Mr. Ahmed were so helpful to process my license on priority basis. Thank you again!',
    rating: 4.8,
  },
  {
    name: 'John Doe',
    review: 'ExpressPRO made the setup process so easy and stress-free. Highly recommended!',
    rating: 4.5,
  },
  // Add more testimonials here
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextReview = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const currentTestimonial = testimonials[currentIndex];

  return (
    <div className="testimonials-container">
      <div className="content-wrapper">
        <div className="header">
          <h2>Your Trusted <span>Business Setup Consultant</span></h2>
          <p>ExpressPRO makes it easier, faster and affordable for you to start your dream business. As one of the leading company formation specialists in the UAE, we work with aspiring entrepreneurs, startups, SMEs and international corporations, enabling them to set up and do business in the UAE smoothly and effectively.</p>
          <ul>
            <li>Lowest cost guarantee</li>
            <li>100% transparency and unbiased advice</li>
            <li>Dedicated Success Manager</li>
            <li>We serve you in 10+ languages</li>
            <li>Top experts in the region</li>
          </ul>
        </div>
        <div className="right-section">
          <div className="rating">
            <h3 className="google-rating-text">
              <span className="google-color">G</span>
              <span className="red-color">o</span>
              <span className="yellow-color">o</span>
              <span className="blue-color">g</span>
              <span className="green-color">l</span>
              <span className="red-color">e</span> Rating
            </h3>
            <div className="stars">
              <span className="rating-value">{currentTestimonial.rating}</span>
              <span className="stars-icons">
                {[...Array(4)].map((_, i) => (
                  <FontAwesomeIcon key={i} icon={faStar} color="#ffb400" />
                ))}
                <FontAwesomeIcon icon={faStarHalfAlt} color="#ffb400" />
              </span>
              <span className="reviews">300+ Reviews</span>
            </div>
          </div>
          <div className="testimonial-box">
            <div className="testimonial-header">
              <img src={googleLogo} alt="Google logo" className="google-logo" />
              <h4>{currentTestimonial.name}</h4>
            </div>
            <p>{currentTestimonial.review}</p>
            <button className="next-arrow" onClick={nextReview}>→</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
